import React, { useMemo } from 'react';
import styles from './index.less';
import img from '../../../assets/images/anchorHome.png';
import { AgreementModal } from './agreenmentModal/index';
import { AuthModal } from './authModal';
import { Model } from './model';

export const DashBoard = () => {
  const store = useMemo(() => {
    return new Model();
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.welcomeContainer}>
          <div className={styles.welcome}>
            Welcome
          </div>
          <div className={styles.welcomeText}>
            欢迎进入直播盈平台
          </div>
        </div>
        <img
          className={styles.logo}
          src={img}
        />
      </div>
      <AgreementModal
        store={store}
      />
      <AuthModal store={store}/>
    </div>
  );
};
