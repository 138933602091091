import { action, computed, observable } from 'mobx';
import type { ContractCheck } from './types';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { API } from './API';

export class Model {
  @observable public hasRealName = true;

  @observable public unSignedContract: ContractCheck = {
    contractNameList: [],
    unSignedNum: 0,
  };

  @observable public realNameModalOpen = false;

  @observable public unSignedContractModalOpen = false;

  constructor() {
    // this.checkTask();
  }

  @action
  public setHasRealName = (hasRealName: boolean) => {
    this.hasRealName = hasRealName;
  };

  @action
  public setUnSignedContract = (data: ContractCheck) => {
    this.unSignedContract = data;
  };

  @action
  public setRealNameModalOpen = (open: boolean) => {
    this.realNameModalOpen = open;
  };

  @action
  public setUnSignedContractModalOpen = (open: boolean) => {
    this.unSignedContractModalOpen = open;
  };

  @action
  public checkTask = async() => {
    const result = await Promise.all([
      this.realNameCheck(),
      this.contractCheck(),
    ]);

    const unsigned = result[1];
    if (unsigned.unSignedNum > 0) {
      this.setUnSignedContract(unsigned);
      this.setUnSignedContractModalOpen(true);
    }

    const realName = result[0];
    if (realName.hasRealname === false) {
      this.setHasRealName(realName.hasRealname);
      this.setRealNameModalOpen(true);
    }
  };

  @action
  public realNameCheck = async() => {
    const result = await request<BaseData<{ hasRealname: boolean; }>>({
      method: 'GET',
      url: API.realNameCheck,
    });
    return result.data;
  };

  @action
  public contractCheck = async() => {
    const result = await request<BaseData<ContractCheck>>({
      method: 'GET',
      url: API.contractCheck,
    });
    return result.data;
  };

  @computed
  public get zIndex(): number {
    return window.top.egenie.activeTabKey ? -1 : 10;
  }
}
