import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import styles from './index.less';
import { nanoid } from 'nanoid';
import type { Model } from '../model';
import { observer } from 'mobx-react';
import { openTab } from '../../utils';

export const AgreementModal = observer((props: { store: Model; }) => {
  const { store } = props;
  const {
    unSignedContractModalOpen,
    unSignedContract,
    setUnSignedContractModalOpen,
    zIndex,
  } = store;

  const contractsList = useMemo(() => {
    return unSignedContract.contractNameList.map((e) => {
      return (
        <div
          className={styles.agreement}
          key={e}
        >
          《
          {e}
          》
        </div>
      );
    });
  }, [unSignedContract]);
  const renderModal = () => {
    return (
      <Modal
        footer={[
          <Button
            key={nanoid()}
            onClick={() => {
              setUnSignedContractModalOpen(false);
            }}
            type="default"
          >
            取消
          </Button>,
          <Button
            key={nanoid()}
            onClick={() => {
              setUnSignedContractModalOpen(false);
              openTab('/egenie-ts-groupservice/contractAgreement', '1506', '合同协议');
            }}
            type="primary"
          >
            去签署
          </Button>,
        ]}
        getContainer={document.getElementById('agreementModal')}
        onCancel={() => {
          setUnSignedContractModalOpen(false);
        }}
        onOk={() => {
          console.log(1);
          setUnSignedContractModalOpen(false);
        }}
        open={unSignedContractModalOpen}
        title={(
          <span className={styles.title}>
            您有
            <span className={styles.num}>
              {unSignedContract.unSignedNum}
            </span>
            份合同待签署
          </span>
        )}
      >
        <div className={styles.agreementContainer}>
          {contractsList}
        </div>
      </Modal>
    );
  };
  return (
    <div
      className={styles.dialogModal}
      id="agreementModal"
      style={{ zIndex }}
    >
      <div>
        {renderModal()}
      </div>
    </div>
  );
});
