
import React, { useEffect } from 'react';
import { LayoutGuide, LayoutStore } from '../../utils/layout';
import { DashBoard } from './dashBoard';
import styles from './index.less';
import { api } from '../../utils';

const layoutStore = new LayoutStore({
  project: {
    name: '',
    value: 'egenie-ts-groupService',
  },
});
layoutStore.handleInit();

/**
 * 调整首页ico
 */
const useAsLogo = () => {
  useEffect(() => {
    const link = document.querySelector<HTMLLinkElement>('link[rel*=\'icon\']');
    link.href = `${process.env.REACT_APP_OSS}customer-source/common/as.ico`;
  }, []);
};

const AnchorHome = () => {
  useAsLogo();
  return (
    <div>
      <LayoutGuide
        defaultDashboard={<DashBoard/>}
        haveDashboard={false}
        logoImg={(
          
          <img
            className={styles.logo}
            src={`${api.oss}/images/asLogo.png`}
          />
        )}
        store={layoutStore}
      />
    </div>
  );
};

export default AnchorHome;
