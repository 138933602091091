import React from 'react';
import { Button, Modal } from 'antd';
import styles from './index.less';
import { nanoid } from 'nanoid';
import type { Model } from '../model';
import { observer } from 'mobx-react';
import { openTab } from '../../utils';

export const AuthModal = observer(({ store }: { store: Model; }) => {
  const {
    realNameModalOpen,
    setRealNameModalOpen,
    hasRealName,
    zIndex,
  } = store;
  const renderModal = () => {
    return (
      <Modal
        footer={[
          <Button
            key={nanoid()}
            onClick={() => {
              setRealNameModalOpen(false);
            }}
            type="default"
          >
            取消
          </Button>,
          <Button
            key={nanoid()}
            onClick={() => {
              setRealNameModalOpen(false);
              openTab('/egenie-ts-payment/accountCenter', '2684', '支付账户中心');
            }}
            type="primary"
          >
            去实名
          </Button>,
        ]}
        getContainer={document.getElementById('authModal')}
        onCancel={() => {
          setRealNameModalOpen(false);
        }}
        open={realNameModalOpen}
        title="提示"
      >
        您还未实名，无法进行分账，请尽快实名。
      </Modal>
    );
  };
  return (
    <div
      className={styles.dialogModal}
      id="authModal"
      style={{ zIndex }}
    >
      <div>
        {renderModal()}
      </div>
    </div>
  );
});
